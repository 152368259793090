<template>
  <div class="flex flex-row items-center justify-between">
    <h1 class="text-2xl font-semibold text-gray-900">
      {{ typeLabel }} - Overzicht
    </h1>
    <UISelectFixed v-model="filters.journal_id" type="FINANCIAL_JOURNALS" />
  </div>

  <div
    v-if="store.getters.hasKantoorPermission"
    class="flex flex-col md:flex-row gap-2 md:gap-8 md:justify-between mt-4"
  >
    <button
      v-if="isInvoice"
      class="m-0"
      :disabled="loading"
      @click="handleAdd"
    >
      + {{ typeLabel }} toevoegen
    </button>
    <button
      v-if="isInvoice && filters.journal_id == 1"
      class="m-0"
      :disabled="loading"
      @click="handleGenereerMaandelijks"
    >
      + Facturen automatisatie/genereren
    </button>
  </div>

  <div class="mt-4" :class="{ 'grid lg:grid-cols-12': isInvoice }">
    <div v-if="isInvoice && data" class="col-span-2 flex flex-col justify-between pr-2 gap-2 text-left">
      <div class="flex flex-col gap-2">
        <UIDarkCard title="Totaal open">
          {{ formatEuro(data.open) }}
          <small class="block text-gray-600">
            <span class="text-red-500">Achterstallig</span>: {{ formatEuro(data.late) }}
          </small>
        </UIDarkCard>
        <UIDarkCard title="Totaal betaald">
          {{ formatEuro(data.paid) }}
        </UIDarkCard>
        <small>
          Berekend op: {{ dateTimeString(data.time) }}<br>
          Herberekening op: {{ dateTimeString(data.time + 1000 * 60 * 15) }}
        </small>
      </div>

      <div
        v-if="store.getters.hasAdminPermission"
        class="flex flex-col gap-2"
      >
        <FacturatieFactoryMultiHandle
          :type="type"
          :journal_id="filters.journal_id"
          @refresh="filters.renderKey += 1"
          @start="loading = true"
          @end="loading = false"
        />
      </div>
    </div>

    <UIDarkCard class="col-span-10" title="Lijst">
      <UILoading v-if="loading" />
      <div v-show="!loading" class="flex flex-col gap-4">
        <div v-if="isInvoice" class="flex flex-col md:flex-row justify-between gap-2">
          <UITabs v-model="tab" :tabs="tabs" class="m-0" />
        </div>
        <div v-if="store.getters.hasAdminPermission" class="flex flex-col lg:flex-row lg:gap-4">
          <UIInput
            :model-value="filters.doc_number"
            label="# Nummer"
            class="w-full max-w-xs"
            placeholder="# Nummer ..."
            @blur="filters.doc_number = $event.target.value || null"
          />
          <UIInput
            :model-value="filters.search_client"
            label="Klant"
            class="w-full max-w-xs"
            placeholder="Klant ..."
            @blur="filters.search_client = $event.target.value || null"
          />
          <UIInputDatePicker
            v-model:date="filters.date_from"
            clearable
            label="Van"
            class="text-left"
          />
          <UIInputDatePicker
            v-model:date="filters.date_to"
            clearable
            label="Tot"
            class="text-left"
          />
          <UIInput
            type="number"
            :model-value="filters.total_price"
            label="Prijs"
            class="w-full max-w-xs"
            placeholder="Prijs 1.23"
            @blur="filters.total_price = $event.target.value || null"
          />
        </div>
        <KeepAlive :key="JSON.stringify(Object.assign({}, filters, { type }))">
          <component
            :is="Views[tab]"
            :filters="filtersApiData"
            :type="type"
            :type-label="typeLabel"
          />
        </KeepAlive>
      </div>
    </UIDarkCard>
  </div>
</template>

<script setup>
import { computed, defineAsyncComponent, ref } from 'vue'
import { useStore } from 'vuex'
import clone from 'just-clone'

import FacturatieFactoryMultiHandle from './MultiHandle.vue'

import UIDarkCard from '@/components/UI/DarkCard.vue'
import UIInputDatePicker from '@/components/UI/Input/DatePicker.vue'
import UIInput from '@/components/UI/Input/Input.vue'
import UILoading from '@/components/UI/Loading'
import UISelectFixed from '@/components/UI/Select/Fixed.vue'
import UITabs from '@/components/UI/Tabs.vue'

import { dateTimeString } from '@/functions/formatDate'
import { formatEuro } from '@/functions/formatNumber'
import useGetApi from '@/hooks/useGetApi'
import useRedirectSafe from '@/hooks/useRedirectSafe'
import useRouteQueryDate from '@/hooks/useRouteQueryDate'
import useRouteQueryInt from '@/hooks/useRouteQueryInt'
import useRouteQueryString from '@/hooks/useRouteQueryString'
// import useRedirectHref from '@/hooks/useRedirectHref'

const props = defineProps({
  type: {
    type: String,
    default: 'INVOICE',
  },
  typeLabel: {
    type: String,
    default: 'Factuur',
  },
})

const store = useStore()
const isInvoice = computed(() => props.type === 'INVOICE')

const loading = ref(false)
const tabs = ['Alles', 'Achterstallig', 'Openstaand', 'Gesloten']
const status = ['ALLES', 'EXPIRED', 'OPEN', 'CLOSED']
const tab = ref(isInvoice.value ? 0 : 2)
const Views = computed(() => tabs.map(() => defineAsyncComponent(() => import('./Table.vue'))))

const filters = ref({
  journal_id: useRouteQueryInt('journal_id', 1),
  client_id: useRouteQueryInt('facturatie', null),
  doc_number: useRouteQueryString('doc', null),
  search_client: useRouteQueryString('search_client', null),
  date_from: useRouteQueryDate('from', null),
  date_to: useRouteQueryDate('to', null),
  total_price: null,
  type: props.type,
  renderKey: 0,
})

const filtersApiData = computed(() => {
  const data = clone(filters.value)
  data.status = status[tab.value]
  delete data.renderKey
  return data
})

const handleAdd = () => {
  useRedirectSafe(`/facturatie/facturen/nieuw?journal_id=${filters.value.journal_id}`)
}

const handleGenereerMaandelijks = () => {
  useRedirectSafe('/facturatie/facturen/maandelijks')
}

const data = ref(null)
const statsApiData = computed(() => ({ journal_id: filters.value.journal_id }))

if (isInvoice.value) {
  const onData = _data => data.value = _data
  useGetApi('FINANCIAL_DOCS_STATS', statsApiData, { onData, watch: true })
}
</script>
