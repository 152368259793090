<template>
  <div>
    <h2 class="text-base text-center m-0">
      Bulkactie
    </h2>
    <div class="flex flex-col gap-2 border p-1 -mx-1">
      <div class="flex flex-row gap-2">
        <UIInput
          v-model="downloadData.van"
          :transform="str => str.replace(/[^\d.]+/g, '')"
          label="Van # Nummer"
          details="Deze inclusief!"
          class="w-full max-w-xs"
          :placeholder="`${nowYear}0099`"
          @update:model-value="downloadData.tot = Math.max(downloadData.tot || 0, downloadData.van)"
        />
        <UIInput
          v-model="downloadData.tot"
          :transform="str => str.replace(/[^\d.]+/g, '')"
          label="Tot # Nummer"
          details="Deze inclusief!"
          class="w-full max-w-xs"
          :placeholder="`${nowYear}0099`"
        />
      </div>
      <div class="flex flex-col gap-3">
        <button class="btn green" :disabled="downloadDisabled" @click="onHandleDownload">
          Downloaden
        </button>
        <button
          v-if="store.getters.hasKantoorPermission"
          class="btn error"
          :disabled="downloadDisabled"
          @click="onHandleVerwijder"
        >
          Verwijderen
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, defineEmits, ref } from 'vue'
import { useStore } from 'vuex'

import UIInput from '@/components/UI/Input/Input.vue'

import isPopupHidden from '@/functions/isPopupHidden'
import useApi from '@/hooks/useApi'
import useModal from '@/hooks/useModal'

const props = defineProps({
  type: String,
  journal_id: [Number, String],
})

const emit = defineEmits(['start', 'end', 'refresh'])

const nowYear = new Date().getFullYear()

const store = useStore()
const api = useApi()
const [openFixedModal] = useModal({ fixed: true })

const isDownloadAvailable = (van, tot) => van > 20230000 && van < 99999999 && tot > 20230000 && tot < 99999999 && tot >= van

const downloadData = ref({
  van: '',
  tot: '',
})

const downloadDisabled = computed(() => {
  const { van, tot } = downloadData.value
  return !isDownloadAvailable(van, tot)
})

const onHandleVerwijder = () => {
  if (downloadDisabled.value) return
  const { van, tot } = downloadData.value

  if (String(van).substring(0, 4) !== String(tot).substring(0, 4)) {
    alert('Te verwijderen facturen dienen van hetzelfde jaar (van/tot nummering) te zijn voor fouten te vermijden.')
    return
  }

  const doc_numbers = []
  for (let doc_number = Number(van); doc_number <= Number(tot); doc_number += 1) {
    doc_numbers.push(doc_number)
  }

  const callback = () => {
    emit('start', true)
    api('FINANCIAL_DOCS_DELETE', { journal_id: props.journal_id, type: props.type, doc_numbers }).then(() => {
      emit('refresh', true)
    }).finally(() => {
      emit('end', true)
    })
  }

  openFixedModal('BEVESTIG_ACTIE', { delay: 5, message: `Documenten van ${van} tot ${tot} verwijderen?`, callback })
}

const onHandleDownload = () => {
  if (downloadDisabled.value) return
  const { van, tot } = downloadData.value

  const callback = async () => {
    emit('start', true)

    for (let doc_number = Number(van); doc_number <= Number(tot); doc_number += 1) {
      const newWin = window.open(`/api/shared/files/financial/${props.type}/${props.journal_id}-${Date.now()}-${doc_number}?print=true`)
      if (isPopupHidden(newWin)) {
        alert('Popups zijn geblokkeert, gelieve dit toe te laten in je browser instellingen.')
        break
      }
      await new Promise(r => newWin.addEventListener('pdfsaved', () => r(true), false))
      newWin.close()
    }

    emit('end', true)
  }

  openFixedModal('BEVESTIG_ACTIE', { message: `Documenten van ${van} tot ${tot} downloaden?`, callback })
}
</script>
